import { Config, ConfigEnvironment } from './ConfigType';

export const config: Config = {
  env: ConfigEnvironment.STAGING,
  release: '6cacbd96b79fb5d385f01341a897fe21494cc770',
  isDebug: false,
  gTagID: 'G-TN1S2X7LCH',
  appProtocol: 'quizadorc',
  domain: 'https://site.symulatorfamiliady.pl',
  sentryDSN: 'https://b860a7e11a19460f836012ebb3964e84@o33089.ingest.sentry.io/5442208',
  API: '/api_api/',
  serverAPI: 'https://api.symulatorfamiliady.pl/',
  imgixHost: 'wg-staging.imgix.net',
  plausible: {
    siteId: 'site.symulatorfamiliady.pl',
    url: 'https://plausible.stampapp.io/'
  },

  strapi: {
    url: 'https://cms.quizado.com',
    token:
      '98aba8ca63004c844ec7766532d7471eeeeb4100adad61a2bfdc91e4ad6d4ae44fa9f96b0dcce576293f43d64b766370cc7fddc9431fff38957bc3a9a83cf2ca783f8f214dcd09baa7a7c941d2b0c90c9b3a3041cb7e9146d29d97ac45cd83a606f0910088ac9989957a9ea3649c72dfe9284b573ff4a967adffddfbe8ce9742'
  }
};
